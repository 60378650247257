import DoodleLeft from "@/public/_ssr/home_hero/doodle_left.png";
import KidBgCutout from "@/public/_ssr/home_hero/home_hero_bg.png";
import BottomLeftSquiggle from "@/public/_ssr/home_hero/home_hero_bottom_left.png";
import TopLeftSquiggle from "@/public/_ssr/home_hero/home_hero_top_left.svg";
import { contentfulImageLoader } from "@/src/contentful/contentfulImageLoader";
import { APP_HOSTNAME, OUTSCHOOL_ENV } from "@/src/Env";
// This file is not yet translated.
/* eslint-disable i18next/no-literal-string */
import { keyframes } from "@emotion/react";
import { Box, Image, Theme, Typography } from "@outschool/backpack";
import { Trans, useTranslation } from "@outschool/localization";
import { onboardingQuizPath } from "@outschool/routes";
import { TrackedButton } from "@outschool/ui-components-shared";
import {
  RollingTexts,
  TrustpilotMicroCombo,
  TrustpilotStarter,
  getWidgetUrlPrefix,
  useMobileSearchBar,
} from "@outschool/ui-components-website";
import { Container } from "@outschool/ui-legacy-component-library";
import {
  pxToRem,
  useIsMobile,
  useNavigation,
  useOnScreen,
} from "@outschool/ui-utils";
import React from "react";

import { WithNextImageProp } from "../lib/SsrImage";

export default function NewUserHero({
  kidOccupations,
  kidImageUrls,
}: {
  kidOccupations: string[];
  kidImageUrls: string[];
}) {
  const { setIsSearchFieldOnScreen } = useMobileSearchBar();
  const searchFieldRef = React.useRef();
  const isOnScreen = useOnScreen(searchFieldRef.current);
  const { t } = useTranslation("ssr-client\\components\\NewUserHeroPhase2");

  React.useEffect(() => {
    setIsSearchFieldOnScreen(isOnScreen);
  }, [setIsSearchFieldOnScreen, isOnScreen]);

  const navigation = useNavigation();
  const Squiggles = (
    <>
      <Box
        sx={(theme: Theme) => ({
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        })}
      >
        <Image
          alt="top left squiggle illustration"
          width={68}
          height={68}
          src={TopLeftSquiggle.src}
        />
      </Box>
      <Box
        sx={(theme: Theme) => ({
          position: "absolute",
          bottom: 0,
          left: 0,
          lineHeight: 0,
          zIndex: 1,
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        })}
      >
        <Image
          alt="bottom left squiggle illustration"
          width={98}
          height={98}
          src={BottomLeftSquiggle.src}
        />
      </Box>
    </>
  );
  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `linear-gradient(180deg, #F0F3FF 0%, #FFFFFF 100%)`,
        backgroundSize: "100% 275px",
        backgroundRepeat: "no-repeat",
      }}
    >
      {Squiggles}
      <Container>
        <Box
          flex
          sx={(theme: Theme) => ({
            flexDirection: "column-reverse",
            [theme.breakpoints.up("md")]: {
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "start",
              paddingBottom: pxToRem(48),
            },
            [theme.breakpoints.up("lg")]: {
              paddingTop: pxToRem(56),
              paddingLeft: pxToRem(16),
              paddingRight: pxToRem(16),
              paddingBottom: "4em",
              justifyContent: "space-between",
            },
            alignItems: "center",
            paddingTop: pxToRem(33),
          })}
        >
          <Box
            sx={(theme: Theme) => ({
              display: "inline-flex",
              flexDirection: "column",
              gap: "1em",
              alignItems: "center",
              width: "100%",
              [theme.breakpoints.down("sm")]: {
                maxWidth: "406px",
              },
              [theme.breakpoints.up("sm")]: {
                width: "406px",
              },
              [theme.breakpoints.up("md")]: {
                width: "406px",
              },
              [theme.breakpoints.up("lg")]: {
                alignItems: "start",
                width: "560px",
                textAlign: "left",
                paddingTop: pxToRem(22),
                marginTop: theme.spacing(19),
              },

              textAlign: "center",
            })}
          >
            <Typography
              emphasized={true}
              sx={(theme: Theme) => ({
                position: "relative",
                marginBottom: pxToRem(32),
                textAlign: "left",
                typography: "h2",
                [theme.breakpoints.up("lg")]: {
                  typography: "h1",
                },
                [theme.breakpoints.up("xl")]: {
                  "&::before": {
                    position: "absolute",
                    content: '""',
                    height: "81px",
                    width: "50px",
                    left: "-60px", // 50px width + 10px margin
                    top: "-20px", // looks nice
                    backgroundImage: `url(${DoodleLeft.src})`,
                  },
                },
              })}
            >
              <Trans t={t}>
                Expert educators and tutors for your future{" "}
                <RollingTexts
                  texts={kidOccupations}
                  textSx={{
                    background:
                      "linear-gradient(90deg, #4801D4 0.1%, #FF43BD 97.13%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  pauseSeconds={3}
                  transitionSeconds={0.6}
                />
              </Trans>
            </Typography>
            <TrackedButton
              trackingName="onboarding-quiz-get-started-button"
              variant="contained"
              sx={(theme: Theme) => ({
                [theme.breakpoints.up("md")]: {
                  alignSelf: "start",
                },
              })}
              onClick={() =>
                navigation(onboardingQuizPath(), { hardNav: true })
              }
            >
              {t("Get Started")}
            </TrackedButton>
            <TrustpilotWidget />
          </Box>
          <RotatingKids
            kidImageUrls={kidImageUrls}
            pauseSeconds={3.3}
            transitionSeconds={0.3}
          />
        </Box>
      </Container>
    </Box>
  );
}

function TrustpilotWidget() {
  const isMobile = useIsMobile();
  return isMobile ? (
    <TrustpilotStarter
      sx={{ width: "125%", transform: "scale(0.8)" }}
      urlPrefix={getWidgetUrlPrefix({
        isSSR: true,
        env: OUTSCHOOL_ENV,
        appHostname: APP_HOSTNAME,
      })}
    />
  ) : (
    <TrustpilotMicroCombo
      urlPrefix={getWidgetUrlPrefix({
        isSSR: true,
        env: OUTSCHOOL_ENV,
        appHostname: APP_HOSTNAME,
      })}
    />
  );
}

function RotatingKids({
  kidImageUrls,
  pauseSeconds,
  transitionSeconds,
}: {
  kidImageUrls: string[];
  pauseSeconds: number;
  transitionSeconds: number;
}) {
  const timePerImage = pauseSeconds + transitionSeconds;
  const totalDuration = kidImageUrls.length * timePerImage;

  const toPercent = (time: number) => (time / totalDuration) * 100;
  const fadeOutStart = toPercent(pauseSeconds);
  const fadeOutEnd = toPercent(pauseSeconds + transitionSeconds);
  const fadeInStart = 100 - toPercent(transitionSeconds);
  /*
  The opacity animation for a single image looks like this:
   ______
         \______________________________/
  And then we give each image a delay
   ______
    img1  \______________________________/
            ______
             img2 \______________________________/
                    ______
                     img3 \______________________________/
   So as a whole the opacity looks like this:
   ______  ______  ______  ______
    img1 \/ img2 \/ img3 \/ img4 \/
  */
  const swap = keyframes`
                  0% { opacity: 1; }
    ${fadeOutStart}% { opacity: 1; } 
    ${fadeOutEnd}%   { opacity: 0; }
    ${fadeInStart}%  { opacity: 0; }
                100% { opacity: 1; }`;

  return (
    <Box
      sx={(theme: Theme) => ({
        flexShrink: 0,
        width: "340px",
        height: "297px",
        [theme.breakpoints.up("md")]: {
          width: "308px",
          height: "268px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "509px",
          height: "444px",
        },
        position: "relative",
        zIndex: 1,
        overflow: "hidden",
        display: "inline-flex",
        alignItems: "center",
        gridArea: "image",
      })}
    >
      {/* This extra Box with the Bg in it might not be needed later if we figure out a better crossfade 
      // For now, the background flickers a bit in between the two images without this
      */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: -1,
        }}
      >
        <Image<WithNextImageProp>
          nextImage={{
            priority: true,
            layout: "fill",
          }}
          src={KidBgCutout.src}
          alt="decorative image"
        />
      </Box>
      {[...new Set(kidImageUrls)].map((url, idx) => (
        <Image<WithNextImageProp>
          alt={"image of kid " + idx}
          key={url}
          src={url}
          sx={{
            animation: `${swap} ${totalDuration}s ease-in-out infinite`,
            //The - totalDuration is because otherwise all the images display
            //at the same time initially. This makes the browser pretend 1
            //loop of animation has happened.
            animationDelay: `${idx * timePerImage - totalDuration}s`,
          }}
          sizes={`(max-width: 768px) 80vw, 50vw`}
          nextImage={{
            priority: idx === 0,
            layout: "fill",
            loader: contentfulImageLoader,
          }}
        />
      ))}
    </Box>
  );
}
